import {defineNuxtRouteMiddleware, navigateTo} from "nuxt/app";
import {useHttp} from "~/composables/httpClient";
import {useUser} from "~/stores/UserStore";
import {useSurveyStore} from "~/stores/SurveyStore";
import {Survey} from "~/model/Survey";
import {Section} from "~/model/Section";
import SectionForm from "~/forms/SectionForm";
import SurveyForm from "~/forms/SurveyForm";
import {Question} from "../model/Question";
import QuestionForm from "../forms/QuestionForm";
import CohortForm from "~/forms/CohortForm";
import {Cohort} from "~/model/Cohort";
import {Sample} from "~/model/Sample";
import SampleForm from "~/forms/SampleForm";
import * as jose from 'jose'
export default defineNuxtRouteMiddleware(async (to, from) => {

    if (!to.params.surveyId || to.params.surveyId == 'new') {
        useSurveyStore().surveyId = null;
        useSurveyStore().survey = new Survey({});
        useSurveyStore().surveyForm.value = SurveyForm({})
    } else if (to.params.surveyId) {
        useSurveyStore().surveyId = to.params.surveyId;
    }

    if (!to.params.sectionId || to.params.sectionId == 'new') {
        useSurveyStore().sectionId = null;
        useSurveyStore().section = new Section({});
        useSurveyStore().sectionForm.value = SectionForm({})

    } else if (to.params.sectionId) {
        useSurveyStore().sectionId = to.params.sectionId;
    }

    if (!to.params.questionId || to.params.questionId === 'new') {
        useSurveyStore().questionId = null;
        useSurveyStore().question = new Question({});
        useSurveyStore().questionForm.value = QuestionForm({})
    } else {
        useSurveyStore().questionId = to.params.questionId;
    }

    if (!to.params.cohortId || to.params.cohortId === 'new') {
        useSurveyStore().cohortId = null;
        useSurveyStore().cohort = new Cohort({});
        useSurveyStore().cohortForm.value = CohortForm({})
    } else {
        useSurveyStore().cohortId = to.params.cohortId;
    }

    if (!to.params.sampleId || to.params.sampleId === 'new') {
        useSurveyStore().sampleId = null;
        useSurveyStore().sample = new Sample({});
        useSurveyStore().sampleForm.value = SampleForm({})
    } else {
        useSurveyStore().sampleId = to.params.sampleId;
    }


    switch (to.path) {
        case '/':
            window.localStorage.removeItem('survey_token')
            break;
        case '/collaborators':
            window.localStorage.removeItem('collaborator_token')
            break;
        case '/login':
            window.localStorage.removeItem('token')
            break;
        case '/logout':
            window.localStorage.removeItem('token')
            return navigateTo('/login')
        default:
            if (to.path.indexOf('admin') >= 0) {
                useUser().pageRole = 'admin'
                if (window.localStorage.getItem('token') && useUser().user === null) {
                    useUser().token = window.localStorage.getItem('token')
                    let {data: user, error} = await useHttp().get('/users/me');
                    if (error.value) {
                        window.localStorage.removeItem('token')
                        return navigateTo('/login')
                    }
                    useUser().user = user.value;


                    let decodedToken = jose.decodeJwt(useUser().token)
                    useUser().scopes = decodedToken.scopes
                } else if (window.localStorage.getItem('token')) {
                    useUser().token = window.localStorage.getItem('token')
                    break;
                } else {
                    return navigateTo('/login')
                }
            } else if (to.path.indexOf('/collaborators') >= 0) {
                useUser().pageRole = 'collaborators'

                if (window.localStorage.getItem('collaborator_token') == null) {
                    return navigateTo('/collaborators')
                } else {
                    useUser().token = window.localStorage.getItem('collaborator_token')
                }

            } else {
                if (window.localStorage.getItem('survey_token') == null) {
                    return navigateTo('/')
                } else {
                    useUser().token = window.localStorage.getItem('survey_token')
                }

            }
    }

})
