
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexOSiC3KhSnhMeta } from "/home/denis/sources/ismat/client/pages/admin/index.vue?macro=true";
import { default as indexp1xXuMkHUDMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/index.vue?macro=true";
import { default as indexh0Ao6e5ubuMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/index.vue?macro=true";
import { default as indexgngXBxMe28Meta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/question/[questionId]/index.vue?macro=true";
import { default as _91cohortId_93aK5PeDDn0HMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/cohorts/[cohortId].vue?macro=true";
import { default as indexzQnQBSAVTLMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/index.vue?macro=true";
import { default as accessx1duDUATn3Meta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/access.vue?macro=true";
import { default as indexC4L2vgMRtWMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/index.vue?macro=true";
import { default as _91sampleId_93X1ZJSwetITMeta } from "/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/samples/edit/[sampleId].vue?macro=true";
import { default as _91id_93K7PPLpuK2nMeta } from "/home/denis/sources/ismat/client/pages/admin/users/[id].vue?macro=true";
import { default as indexg3YCXF0Kw4Meta } from "/home/denis/sources/ismat/client/pages/admin/users/index.vue?macro=true";
import { default as indexSbvFyQNjFxMeta } from "/home/denis/sources/ismat/client/pages/collaborators/index.vue?macro=true";
import { default as indexHyP0IpxMOrMeta } from "/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/index.vue?macro=true";
import { default as _91sampleId_93aw3QFEjiD8Meta } from "/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/samples/edit/[sampleId].vue?macro=true";
import { default as indexZ5iptvU8XiMeta } from "/home/denis/sources/ismat/client/pages/index.vue?macro=true";
import { default as loginTyMAxjhfbSMeta } from "/home/denis/sources/ismat/client/pages/login.vue?macro=true";
import { default as indexTj3nGIzPR1Meta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/index.vue?macro=true";
import { default as _91sectionId_93Um0Uvm7AcRMeta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/section/[sectionId].vue?macro=true";
import { default as validationK7bbYw1J0cMeta } from "/home/denis/sources/ismat/client/pages/survey/[surveyId]/validation.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexOSiC3KhSnhMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/index.vue")
  },
  {
    name: "admin-survey-surveyId-creation",
    path: "/admin/survey/:surveyId()/creation",
    meta: indexp1xXuMkHUDMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/index.vue")
  },
  {
    name: "admin-survey-surveyId-creation-section-sectionId",
    path: "/admin/survey/:surveyId()/creation/section/:sectionId()",
    meta: indexh0Ao6e5ubuMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/index.vue")
  },
  {
    name: "admin-survey-surveyId-creation-section-sectionId-question-questionId",
    path: "/admin/survey/:surveyId()/creation/section/:sectionId()/question/:questionId()",
    meta: indexgngXBxMe28Meta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/creation/section/[sectionId]/question/[questionId]/index.vue")
  },
  {
    name: "admin-survey-surveyId-diffusion-cohorts-cohortId",
    path: "/admin/survey/:surveyId()/diffusion/cohorts/:cohortId()",
    meta: _91cohortId_93aK5PeDDn0HMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/cohorts/[cohortId].vue")
  },
  {
    name: "admin-survey-surveyId-diffusion",
    path: "/admin/survey/:surveyId()/diffusion",
    meta: indexzQnQBSAVTLMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/diffusion/index.vue")
  },
  {
    name: "admin-survey-surveyId-results-access",
    path: "/admin/survey/:surveyId()/results/access",
    meta: accessx1duDUATn3Meta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/access.vue")
  },
  {
    name: "admin-survey-surveyId-results",
    path: "/admin/survey/:surveyId()/results",
    meta: indexC4L2vgMRtWMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/index.vue")
  },
  {
    name: "admin-survey-surveyId-results-samples-edit-sampleId",
    path: "/admin/survey/:surveyId()/results/samples/edit/:sampleId()",
    meta: _91sampleId_93X1ZJSwetITMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/survey/[surveyId]/results/samples/edit/[sampleId].vue")
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    meta: _91id_93K7PPLpuK2nMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexg3YCXF0Kw4Meta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/admin/users/index.vue")
  },
  {
    name: "collaborators",
    path: "/collaborators",
    meta: indexSbvFyQNjFxMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/index.vue")
  },
  {
    name: "collaborators-survey-surveyId-results",
    path: "/collaborators/survey/:surveyId()/results",
    meta: indexHyP0IpxMOrMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/index.vue")
  },
  {
    name: "collaborators-survey-surveyId-results-samples-edit-sampleId",
    path: "/collaborators/survey/:surveyId()/results/samples/edit/:sampleId()",
    meta: _91sampleId_93aw3QFEjiD8Meta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/collaborators/survey/[surveyId]/results/samples/edit/[sampleId].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexZ5iptvU8XiMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTyMAxjhfbSMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/login.vue")
  },
  {
    name: "survey-surveyId",
    path: "/survey/:surveyId()",
    meta: indexTj3nGIzPR1Meta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/index.vue")
  },
  {
    name: "survey-surveyId-section-sectionId",
    path: "/survey/:surveyId()/section/:sectionId()",
    meta: _91sectionId_93Um0Uvm7AcRMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/section/[sectionId].vue")
  },
  {
    name: "survey-surveyId-validation",
    path: "/survey/:surveyId()/validation",
    meta: validationK7bbYw1J0cMeta || {},
    component: () => import("/home/denis/sources/ismat/client/pages/survey/[surveyId]/validation.vue")
  }
]