import {defineStore, storeToRefs} from "pinia";
import User from "~/models/User";
import { watch, Ref } from "vue";
import {useHttp} from "~/composables/httpClient";
import QuestionForm from "~/forms/QuestionForm";

export const useUser = defineStore("user", () => {
    const user: Ref<User | null> = ref(null);
    const users: Ref<User[] | null> = ref([]);
    const token: Ref<String | null> = ref(null)
    const scopes: Ref<String[] | null> = ref([])
    const pageRole: Ref<String | null> = ref(null)
    const language:string = localStorage.getItem('lang')

    const getUsers = async () => {
        let {
            data,
            error
        } = await useHttp().get(`/users`)
        if (data.value) {
            users.value = data.value;
        }
    }

    if(users.value?.length == 0) {
        getUsers();
    }

    return {user, token, pageRole, language, users, getUsers, scopes};

})
