import { Question } from "./Question";

import { SurveyStatus } from "./SurveyStatus";
import { Section } from "~/model/Section";

export class Survey {
    uuid: string | undefined;
    title: string;
    status: SurveyStatus;
    company_name: string;
    deadline: Date;
    description_pdf: string;
    description_web: string;
    contact: string;
    sections: Section[]
    logo: string | undefined
    image: string | undefined
    privacy_policy: string | undefined
    estimated_duration: number | undefined
    lang: string | undefined
    min_respondent_count: number | undefined
    bypass_min_respondent_count: boolean | undefined
    excluded_users: string[] | undefined

    constructor({ uuid, title, description_web, description_pdf, contact, status, sections, company_name, deadline, logo, image, privacy_policy, estimated_duration, lang, min_respondent_count,  bypass_min_respondent_count, excluded_users}:
        { uuid?: string, title: string, description_web: string, description_pdf: string, contact: string, status: SurveyStatus, sections: Section[], company_name: string, deadline: Date, logo?:string, image?:string, privacy_policy?:string, estimated_duration?:number, lang?:string, min_respondent_count?:number, bypass_min_respondent_count?:boolean, excluded_users?:string[]  }) {

        this.uuid = uuid;
        this.title = title;
        this.description_pdf = description_pdf;
        this.description_web = description_web;
        this.contact = contact;
        this.status = status;
        this.sections = sections;
        this.company_name = company_name;
        this.deadline = deadline;
        this.logo = logo;
        this.image = image;
        this.privacy_policy = privacy_policy;
        this.lang = lang;
        this.estimated_duration = estimated_duration;
        this.min_respondent_count = min_respondent_count;
        this.bypass_min_respondent_count = bypass_min_respondent_count;
        this.excluded_users = excluded_users;
    }

    getSectionQuestions(section: number): Question[] {
        return this.sections.filter(q => q.section === section)[0].questions
    }

    nbQuestions(section: number): number {
        return this.getSectionQuestions(section).length
    }
}
